import {
    Box,

    Grid, Link, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/GetApp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import React from 'react';

export default function DigitalItems({ digitalItems, user }) {
    const flipBookViewer = '/quickview2.html';
    const flipBookReact = `${window.location.origin}/#/flipbook`

    return (

        <Box mt={2} mb={2}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h6" gutterBottom component="div">
                        Digital
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="address table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Action</TableCell>
                                    <TableCell align="left">Product</TableCell>
                                    <TableCell align="center">Output Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    digitalItems.map((di) => (
                                        <TableRow key={di.lineId}>
                                            <TableCell align="center">
                                                {
                                                    di.isImg
                                                        ? (
                                                            <Link
                                                                href={`${di.downloadLink}?Authorization=${user.token}`}
                                                                target="_blank"
                                                                style={{ marginLeft: '1rem' }}
                                                                color="primary"
                                                            >
                                                                <CloudDownloadIcon size="small" />
                                                            </Link>
                                                        )
                                                        : (
                                                            <Link
                                                                href={di.customFields.crosswalk.brand === 'a1a' 
                                                                    ? `${flipBookReact}/${di.customFields.crosswalk.partner_name || 'partner'}/${(di.customFields.crosswalk.preview.replace('/preview/', `/flip2/${+new Date()}`)).split('/flip2/')[1]}` 
                                                                    : `${flipBookViewer}?pdf=${(di.customFields.crosswalk.preview.replace('/preview/', `/flip2/${+new Date()}`))}&a=&p=`}
                                                                target="_blank"
                                                                style={{ marginLeft: '1rem' }}
                                                                color="primary"
                                                            >
                                                                <ImportContactsIcon size="small" />
                                                            </Link>
                                                        )
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <img style={{ maxWidth: '50px' }} alt={di.customFields.alias || di.name} src={di.customFields.thumbnail} />
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        {di.customFields.alias || di.name}
                                                    </Grid>
                                                </Grid>

                                            </TableCell>
                                            <TableCell align="center">
                                                {di.customFields.outputType}
                                            </TableCell>
                                        </TableRow>

                                    ))
                                }
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}
